import httpClient from '../http'
const ENDPOINT = 'api/position'

const latestPositions = (profile) => httpClient.get(ENDPOINT+'?userid='+profile,{});

const history = (data) => httpClient.get(ENDPOINT+'/history'+data);

export default{
    latestPositions,
    history
}