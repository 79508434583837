import httpClient from '../http'
const ENDPOINT = 'api/support'

const getSupportList = () => httpClient.get(ENDPOINT);

const addSupport = (data) => httpClient.post(ENDPOINT,data);

const updateSupport = (data) => httpClient.patch(ENDPOINT,data);


export default{
    getSupportList,
    addSupport,
    updateSupport
}