import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import WebFont from 'webfontloader';
import './plugins'
import x5GMaps from 'x5-gmaps';
import storage from './store';
import functions from './plugins/funcs';
import './registerServiceWorker'
import DatetimePicker from 'vuetify-datetime-picker'

// Option 1: Just your key
Vue.use(x5GMaps, process.env.VUE_APP_GOOGLE_MAPS_API);
// Option 2: With libraries
//Vue.use(x5GMaps, { key: 'YOUR_GOOGLE_KEY', libraries: ['places'] });
Vue.use(DatetimePicker);

Vue.config.productionTip = false;

Vue.prototype.$func = functions;

WebFont.load({
  google: { families: ["PT Sans:400,700", "Work Sans:100,300,400,500,700,900"] }
});

new Vue({
  vuetify,
  router,
  store:storage,
  render: h => h(App)
}).$mount('#app')
