import axios from 'axios';
import router from '../router'

console.log(process.env.NODE_ENV=='development'?process.env:'');
const httpClient = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: {
        "Content-Type": "application/json",
        // anything you want to add to the headers
    }
});

function getAuthToken(){
    return "Bearer " + localStorage.getItem("token");
}

const authInterceptor = (config) => {
    if(localStorage.getItem("token") !== null)
        config.headers['Authorization'] = getAuthToken();
    return config;
}

httpClient.interceptors.request.use(authInterceptor, 
    function (error) {
     //   console.log(error);
    // Do something with request error
    return Promise.reject(error);
  });

  httpClient.interceptors.response.use(function(res){
     // console.log(res);
      return res;
  }, 
    function (error) {
        //console.log('from interceptor response error');
        //console.log(error);
        if(error.response.status == 401){
            router.push('/login');
        }
    // Do something with request error
        return Promise.reject(error);
  });


export default httpClient;
