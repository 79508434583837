<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-info-card
        title="Contactanos"
        pace="6"
      >
        En monitor satelital nos interesa saber tus opiniones. Alguna duda o comentario puedes contactarnos en el correo electronico y nos pondremos en contacto con usted en la brevedad posible.
      </base-info-card>

    <v-row>
      <v-col>
      <base-business-contact  />
      </v-col>
      <v-col>
      <base-contact-form></base-contact-form>
      </v-col>
    </v-row>
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseBusinessInfo',

    props: { dark: Boolean },

    
  }
</script>
