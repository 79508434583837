<template>
  <v-form ref="supportForm">
    <base-info-card
      :title="title"
      :subtitle="subtitle"
      space="4"
      color="primary"
    />

    <base-text-field label="Nombre" required v-model="supportData.name" ref="name"
    @keyup.enter="$refs.email.$el.querySelector('input').focus()"
            :rules="[() => !!supportData.name || 'This field is required']" />

    <base-text-field label="Email" required v-model="supportData.email" ref="email"
    @keyup.enter="$refs.subject.$el.querySelector('input').focus()"
            :rules="[() => !!supportData.email || 'This field is required']" />

    <base-text-field label="Asunto" required v-model="supportData.subject" ref="subject"
    @keyup.enter="$refs.description.$el.querySelector('textarea').focus()"
            :rules="[() => !!supportData.subject || 'This field is required']" />

    <base-textarea
      class="mb-6"
      label="Descripcion"
      required
      v-model="supportData.description"
      ref="description"
            :rules="[() => !!supportData.description || 'This field is required']"
    />

    
    <base-re-captcha sitekey="6LcueVUeAAAAAPfZym52ffmdm_24pDCV2HxsOcEY"
      @onSubmit="sendSupport"
      @onError="showError"
    >
      Enviar
    </base-re-captcha> 
    <base-btn
      :color="!theme.isDark ? 'accent' : 'white'"
      outlined
      @click="clearform"
    >
      Limpiar
    </base-btn>

    
  </v-form>
</template>

<script>
import { mapActions } from 'vuex';

  export default {
    name: 'BaseContactForm',

    // Injected from the Vuetify Themeable mixin
    inject: ['theme'],

    props: {
      subtitle: String,
      title: {
        type: String,
        default: 'Escribenos',
      },
    },
    data:()=>({
      supportData:{
        name:"",
        email:"",
        subject:"",
        description:""
      }
    }),
    methods:{
      ...mapActions(['addSupport','showSnackbar']),
      sendSupport(){
        
        const isValid = this.$refs.supportForm.validate();

        if(isValid){
          this.addSupport(this.supportData).then((res)=>{
              //console.log(res);
              if(res.status == 200){
                this.showSnackbar({
                  showing:true,
                  message: `Su mensaje ha sido enviado ${this.supportData.name}, nuestro equipo de soporte se pondra en contacto lo mas pronto posible. Gracias por contactarnos!`,
                  color:'success'
                });
                this.supportData = {
                  name:"",
                  email:"",
                  subject:"",
                  description:""
                }
                this.$refs.supportForm.resetValidation();
              }
          }) .catch((err)=>{
                  console.log(err);
              });
        }
      }
      ,showError(){
         this.showSnackbar({
                  showing:true,
                  message: `No se ha podido contactar al servidor. Favor de intentar mas tarde.`,
                  color:'error'
                });
      }
      ,clearform(){
        this.$refs.supportForm.resetValidation();
      }
    }
  }
</script>
