import httpClient from '../http'
const ENDPOINT = 'api/address'

const countries = () => httpClient.get(ENDPOINT+"/country");

const states = (id) => httpClient.get(ENDPOINT+"/states/"+id);

const cities = (id) => httpClient.get(ENDPOINT+"/cities/"+id);


export default{
    countries
    ,states
    ,cities
}