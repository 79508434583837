<template>
  <!-- <div id="g-recaptcha" class="g-recaptcha" :data-sitekey="sitekey"></div> -->
  <base-btn
      outlined
      class="g-recaptcha"
        :data-sitekey="sitekey"
        @click='acceptCaptcha'
        >
      <slot />
    </base-btn>
</template>

<script>

export default {
  props: {
    sitekey: {
        type:String,
        default: process.env.VUE_APP_GOOGLE_MAPS_API
    },
    action: {
        type: String,
        default: 'contact'
    },
  },
  methods: {
    execute() {
      window.grecaptcha.execute(0);
    },
    reset() {
      window.grecaptcha.reset(0);
    },
    acceptCaptcha(){
        //console.log(token);
        var self = this;
        window.grecaptcha.enterprise.execute(this.sitekey, {action: this.action}).then(function(token) {
             // IMPORTANT: The 'token' that results from execute is an encrypted response sent by
             // reCAPTCHA Enterprise to the end user's browser.
             // This token must be validated by creating an assessment.
             // See https://cloud.google.com/recaptcha-enterprise/docs/create-assessment
             //console.log(token);    
            self.$emit('onSubmit',token);
          }).catch((err)=>{
                  console.log(err);
                  self.$emit('onError',err);
              });
    },
    render() {
      //console.log(window.grecaptcha);
      if (window.grecaptcha) {
          //captchaSubmit();
        console.log("render successful");
        
        this.widgetId = window.grecaptcha.enterprise.render("g-recaptcha", {
          sitekey: this.siteKey,
          // the callback executed when the user solve the recaptcha
          callback: (response) => {
            // emit an event called verify with the response as payload
            this.$emit("verify", response);
            // reset the recaptcha widget so you can execute it again
            this.reset();
          },
        });
      }
    },
  },

  mounted() {
    
    const captchaScript = document.getElementById("captcha-script");
    if(!captchaScript){
      const plugin = document.createElement("script");
      plugin.setAttribute('id','captcha-script');
      plugin.setAttribute(
        "src",
        "https://www.google.com/recaptcha/enterprise.js?render="+this.sitekey
      );
      plugin.async = true;
      //plugin.onloadeddata = this.render();
      document.head.appendChild(plugin);
    }else{
      //captchaScript.onloadeddata = this.render();
    }
/*
    const captchaScript = document.getElementById("captcha-script");
    console.log(captchaScript);
    captchaScript.onloadeddata = this.render();
    */
   setTimeout(function(){
   var recaptchaBadge = document.getElementsByClassName("grecaptcha-badge");
        if(recaptchaBadge.length >0)
          recaptchaBadge.forEach(element => {
            element.style.visibility = "visible";
          });
   },100);
  },
};
</script>


