import users from './api/users'
import position from './api/position'
import devices from './api/devices'
import address from './api/address';
import payment from './api/payment';
import commands from './api/commands';
import sendCommands from './api/sendCommands';
import support from './api/support';

export default {
      users,
      position,
      devices,
      address,
      payment,
      commands,
      sendCommands,
      support,
};