import Vue from 'vue';
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import router from '../router'
import api from '../api/api'

Vue.use(Vuex)

const storage = new Vuex.Store({
    state:{
        isAuthenticated:false,
        user:{
            username:'',
            id: null,
            token:null,
        },
        snackbar:{
            timeout:3000,
        },
        packageVersion: process.env.VUE_APP_VERSION || '0'
    },
    getters:{
        isAuth: (state)=> { return state.isAuthenticated||false},
        isAdmin: (state)=> {return state.user.roleid==1||false},
        Version: (state)=> {return state.packageVersion}
    },
    mutations:{
        setAuthenticated(state,{value,user}){
            state.isAuthenticated = value;
            if(value){
                state.user = user;
                localStorage.setItem('token',user.token);
            }else{
                this.commit('logout');
            }
        },
        setUsername(state,username){
            state.username = username;
        },
        setToken(state,id){
            state.token = id;
        },
        logout(state){
            router.push('/login');
            state.user = {token:''};
            localStorage.removeItem('token');
        },
        setSnackBar(state,value){
            state.snackbar = value;
            //state.snackbar.message.push(value.message)
        },
        clearSnackBar(state){
            state.snackbar.message.shift();
            //state.snackbar.message.push(value.message)
        },
    },

    actions:{

/****** USER */
        logout({commit}){
            commit('setAuthenticated',false);
        },
        login({commit},{user,pass}){
            return new Promise((resolve,reject)=>{
                api.users.login(user,pass).then((response) =>{
                    //console.log(response)
                    if(response.data.data.token){
                        commit('setAuthenticated',{value:true,user:response.data.data});
                    }else{
                        commit('setAuthenticated',false);
                    }
                    resolve(response);
                }).
                catch(err => {
                    commit('setAuthenticated',false);
                    reject(err.response)
                });
                
            
            });            
        },
        getSubUsers({commit},id){
            return new Promise((resolve,reject)=>{
                api.users.subUsers(id).then((response) =>{
                    //console.log(response)
                    resolve(response);
                }).
                catch(err => {
                    if(err.response.status == 401){
                        commit('setAuthenticated',false);
                    }
                    reject(err.response)
                });
                
            
            });            
        },
        deleteUser({commit},id){
            return new Promise((resolve,reject)=>{
                api.users.deleteUser(id).then((response) =>{
                    //console.log(response)
                    resolve(response);
                }).
                catch(err => {
                    if(err.response.status == 401){
                        commit('setAuthenticated',false);
                    }
                    reject(err.response)
                });            
            });            
        },
        
        addUser({commit},user){
            return new Promise((resolve,reject)=>{
                api.users.addUser(user).then((response) =>{
                    //console.log(response)
                    resolve(response);
                }).
                catch(err => {
                    if(err.response.status == 401){
                        commit('setAuthenticated',false);
                    }
                    reject(err.response)
                });            
            });
        },

        updatePass({commit},user){
            return new Promise((resolve,reject)=>{
                api.users.updatePass(user).then((response) =>{
                    //console.log(response)
                    resolve(response);
                }).
                catch(err => {
                    commit('setAuthenticated',false);
                    reject(err.response)
                });
                
            
            });            
        },

        showSnackbar({commit},snackbar){
            commit('setSnackBar',snackbar);
        },
        getProfile({commit},id){
            return new Promise((resolve,reject)=>{
                api.users.profile(id).then((response) =>{
                    resolve(response);
                }).
                catch(err => {
                    commit('setAuthenticated',false);
                    reject(err.response)
                });
                
            
            });    
        },

        getUserList({commit}){
            return new Promise((resolve,reject)=>{
                api.users.userList().then((response) =>{
                    resolve(response);
                }).
                catch(err => {
                    commit('setAuthenticated',false);
                    reject(err.response)
                });
                
            
            });    
        },

        addContact({commit},contact){
            return new Promise((resolve,reject)=>{
                api.users.saveContact(contact).then((response) =>{
                    resolve(response);
                }).
                catch(err => {
                    if(err.response.status == 401){
                        commit('setAuthenticated',false);
                    }
                    reject(err.response)
                });
                
            
            });    
        },

        getLatestPositions({commit},profile){
            return new Promise((resolve,reject)=>{
                api.position.latestPositions(profile).then((response) =>{
                    //console.log(response);
                    
                    var newdata = response.data.data.map((m) =>{
                        m.visible = true;
                        return {
                            position: {lat:m.latitude,lng:m.longitude}
                            ,m
                        };
                    });
                    response.data.newdata = newdata;
                    resolve(response);
                }).
                catch(err => {
                    //console.log(err);
                    if(err.response.status == 401){
                        //router.push('/login');
                        commit('setAuthenticated',false);
                    }
                    //commit('setAuthenticated',false);
                    reject(err.response);
                });
                
            
            });            
        },
        getHistory({commit},data){
            return new Promise((resolve,reject)=>{
                api.position.history(data).then((response) =>{
                    //console.log(response);
                    var newdata = response.data.data.map((m) =>{
                        m.visible = true;
                        m.lat = m.latitude;
                        m.lng = m.longitude;
                        m.position = {lat:m.latitude,lng:m.longitude};
                        return m;
                    });
                    //response.data.newdata = newdata;
                    console.log(newdata);
                    resolve(response);
                }).
                catch(err => {
                    //console.log(err);
                    if(err.response.status == 401){
                        //router.push('/login');
                        commit('setAuthenticated',false);
                    }
                    //commit('setAuthenticated',false);
                    reject(err.response);
                });
            });            
        },

        getUserDevices({commit},userid){
            return new Promise((resolve,reject)=>{
                api.users.userDevices(userid).then((response) =>{
                    resolve(response);
                }).
                catch(err => {
                    if(err.response.status == 401){
                        //router.push('/login');
                        commit('setAuthenticated',false);
                    }
                    //commit('setAuthenticated',false);
                    reject(err.response);
                });
                
            
            });    
        },
/*****DEVICES */
        getDevices({commit},filters){
            return new Promise((resolve,reject) =>{
                api.devices.userDevices(filters).then((response)=>{
                    resolve(response);
                })
                .catch(err => {
                    //console.log(err);
                    if(err.response.status == 401){
                        //router.push('/login');
                        commit('setAuthenticated',false);
                    }
                    //commit('setAuthenticated',false);
                    reject(err.response);
                });
            });
        },

        getAllDevices(){
            return new Promise((resolve,reject) =>{
                api.devices.allDevices().then((response)=>{
                    resolve(response);
                })
                .catch(err => {
                    //console.log(err);
                    if(err.response.status == 401){
                        //router.push('/login');
                        this.commit('setAuthenticated',false);
                    }
                    //commit('setAuthenticated',false);
                    reject(err.response);
                });
            });
        },

        getDeviceTypes({commit}){
            return new Promise((resolve,reject) =>{
                api.devices.deviceTypes().then((response)=>{
                    resolve(response);
                })
                .catch(err => {
                    //console.log(err);
                    if(err.response.status == 401){
                        //router.push('/login');
                        commit('setAuthenticated',false);
                    }
                    //commit('setAuthenticated',false);
                    reject(err.response);
                });
            });
        },

        updateDevice({commit},device){
            //console.log(device);
            return new Promise((resolve,reject) =>{
                api.devices.updateDevice(device).then((response)=>{
                    resolve(response);
                })
                .catch(err => {
                    //console.log(err);
                    if(err.response.status == 401){
                        //router.push('/login');
                        commit('setAuthenticated',false);
                    }
                    //commit('setAuthenticated',false);
                    reject(err.response);
                });
            });
        },

        addDevice({commit},device){
            //console.log(device);
            return new Promise((resolve,reject) =>{
                api.devices.addDevice(device).then((response)=>{
                    resolve(response);
                })
                .catch(err => {
                    //console.log(err);
                    if(err.response.status == 401){
                        //router.push('/login');
                        commit('setAuthenticated',false);
                    }
                    //commit('setAuthenticated',false);
                    reject(err.response);
                });
            });
        },

        getDevicePayments({commit},devId){
            return new Promise((resolve,reject) =>{
                api.devices.devicePayments(devId).then((response)=>{
                    resolve(response);
                })
                .catch(err => {
                    //console.log(err);
                    if(err.response.status == 401){
                        //router.push('/login');
                        commit('setAuthenticated',false);
                    }
                    //commit('setAuthenticated',false);
                    reject(err.response);
                });
            });
        },

        /****** Commands ****** */
        getCommands({commit}){
            return new Promise((resolve,reject) => {
                api.commands.commandList().then((response)=>{
                    resolve(response);
                })
                .catch(err => {
                    if(err.response.status == 401){
                        //router.push('/login');
                        commit('setAuthenticated',false);
                    }
                    //commit('setAuthenticated',false);
                    reject(err.response);
                })
            });
        },

        addCommand({commit},command){
            return new Promise((resolve,reject) => {
                api.sendCommands.addCommand(command).then((response)=>{
                    resolve(response);
                })
                .catch(err => {
                    if(err.response.status == 401){
                        //router.push('/login');
                        commit('setAuthenticated',false);
                    }
                    //commit('setAuthenticated',false);
                    reject(err.response);
                })
            });
        },

        /****** Payments ****** */
        addPayment({commit},payment){
            return new Promise((resolve,reject) =>{
                api.payment.addPayment(payment).then((response)=>{
                    resolve(response);
                })
                .catch(err => {
                    //console.log(err);
                    if(err.response.status == 401){
                        //router.push('/login');
                        commit('setAuthenticated',false);
                    }
                    //commit('setAuthenticated',false);
                    reject(err.response);
                });
            });
        },
        updatePayment({commit},payment){
            return new Promise((resolve,reject) =>{
                api.payment.updatePayment(payment).then((response)=>{
                    resolve(response);
                })
                .catch(err => {
                    //console.log(err);
                    if(err.response.status == 401){
                        //router.push('/login');
                        commit('setAuthenticated',false);
                    }
                    //commit('setAuthenticated',false);
                    reject(err.response);
                });
            });
        },
        deletePayment({commit},payment){
            return new Promise((resolve,reject) =>{
                api.payment.deletePayment(payment).then((response)=>{
                    resolve(response);
                })
                .catch(err => {
                    //console.log(err);
                    if(err.response.status == 401){
                        //router.push('/login');
                        commit('setAuthenticated',false);
                    }
                    //commit('setAuthenticated',false);
                    reject(err.response);
                });
            });
        },

    /* *****  Address *****/
        getCountries({commit}){
            return new Promise((resolve,reject) =>{
                api.address.countries().then((response)=>{
                    resolve(response);
                })
                .catch(err => {
                    //console.log(err);
                    if(err.response.status == 401){
                        //router.push('/login');
                        commit('setAuthenticated',false);
                    }
                    //commit('setAuthenticated',false);
                    reject(err.response);
                });
            });
        },

        getStates({commit},id){
            return new Promise((resolve,reject) =>{
                api.address.states(id).then((response)=>{
                    resolve(response);
                })
                .catch(err => {
                    //console.log(err);
                    if(err.response.status == 401){
                        //router.push('/login');
                        commit('setAuthenticated',false);
                    }
                    //commit('setAuthenticated',false);
                    reject(err.response);
                });
            });
        },

        getCities({commit},id){
            return new Promise((resolve,reject) =>{
                api.address.cities(id).then((response)=>{
                    resolve(response);
                })
                .catch(err => {
                    //console.log(err);
                    if(err.response.status == 401){
                        //router.push('/login');
                        commit('setAuthenticated',false);
                    }
                    //commit('setAuthenticated',false);
                    reject(err.response);
                });
            });
        },

        /********** Support */
        getSupport({commit}){
            return new Promise((resolve,reject) =>{
                api.support.getSupportList().then((response)=>{
                    resolve(response);
                })
                .catch(err => {
                    //console.log(err);
                    if(err.response.status == 401){
                        //router.push('/login');
                        commit('setAuthenticated',false);
                    }
                    //commit('setAuthenticated',false);
                    reject(err.response);
                });
            });
        },
        addSupport({commit},support){
            return new Promise((resolve,reject) =>{
                api.support.addSupport(support).then((response)=>{
                    resolve(response);
                })
                .catch(err => {
                    //console.log(err);
                    if(err.response.status == 401){
                        //router.push('/login');
                        commit('setAuthenticated',false);
                    }
                    //commit('setAuthenticated',false);
                    reject(err.response);
                });
            });
        },
        updateSupport({commit},support){
            return new Promise((resolve,reject) =>{
                api.support.updateSupport(support).then((response)=>{
                    resolve(response);
                })
                .catch(err => {
                    //console.log(err);
                    if(err.response.status == 401){
                        //router.push('/login');
                        commit('setAuthenticated',false);
                    }
                    //commit('setAuthenticated',false);
                    reject(err.response);
                });
            });
        },
    },

    plugins:[
        new VuexPersistence({
            storage: window.localStorage,
            reducer:(state)=> ({user:state.user, isAuthenticated: state.isAuthenticated}),
        }).plugin
    ]
});


export default storage