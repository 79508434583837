import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
  
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
  },
  {
    path: '/About',
    name: 'Nosotros',
    component: () => import('@/views/Nosotros.vue'),
    meta: { src: require('@/assets/about.jpg') },
  },
  {
    path: '/Priv',
    name: 'Privacidad',
    component: () => import('@/views/Priv.vue'),
    meta: { src: require('@/assets/article-4.jpg') },
  },
  {
    path: '/Terms',
    name: 'Terminos',
    component: () => import('@/views/Terms.vue'),
    meta: { src: require('@/assets/article-2.jpg') },
  },
  {
    path: '/app/:id?',
    name: 'Vigilante',
    component: () => import('@/views/Vigilante.vue'),
    meta:{footer:false}
  },
  {
    path: '/history',
    name: 'History',
    component: () => import('@/views/History.vue'),
    meta:{footer:false}
  },
  {
    path: '/FAQ',
    name: 'FAQ',
    component: () => import('@/views/Faq.vue'),
    meta: { src: require('@/assets/about.jpg') },
  },
  {
    path: '/Support',
    name: 'Support',
    component: () => import('@/views/Support.vue')
  },
  {
    path: '/devices',
    name: 'Devices',
    component: () => import('@/views/Devices.vue'),
  },
  {
    path: '/devicelist',
    name: 'Device List',
    component: () => import('@/views/DeviceList.vue'),
  },
  {
    path: '/user/:id/devices',
    name: 'UserDevices',
    component: () => import('@/views/Devices.vue'),
  },
  {
    path: '/device/:id/payments',
    name: 'Payments',
    component: () => import('@/views/Payments.vue'),
  },
  {
    path: '/profile/:id?',
    name: 'Profile',
    component: () => import('@/views/Profile.vue'),
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('@/views/Users.vue'),
  },
  {
    path: '/signout',
    name: 'Sign Out',
    component: () => import('@/views/Signout.vue'),
  },
  {
    /*
    path: '/Login',
    component: () => import('@/layouts/Empty.vue'),
    children: [
      {
        */
        path: '/login',
        name: 'Login',
        component: () => import('@/views/Login.vue'),
//      },
//    ]
  }
]
})

export default router
