import httpClient from '../http'
const ENDPOINT = 'api/login'
const USER_ENDPOINT = 'api/users'

const login = (username,password) => httpClient.post(ENDPOINT,{username,password});

const userList = () => httpClient.get(USER_ENDPOINT+"?PageSize=1000");

const profile = (id) => httpClient.get(USER_ENDPOINT+"/"+id);

const saveContact = (contact) => httpClient.post(USER_ENDPOINT+"/contact",contact);

const updatePass = (pass) => httpClient.put(USER_ENDPOINT,pass);

const subUsers = (id) => httpClient.get(USER_ENDPOINT+"/subuser/"+id);

const deleteUser = (id) => httpClient.delete(USER_ENDPOINT+"/"+id);

const addUser = (user) => httpClient.post(USER_ENDPOINT,user);

const userDevices = (id) => httpClient.get(USER_ENDPOINT+"/"+id+"/devices");


export default{
    login
    ,profile
    ,saveContact
    ,updatePass
    ,subUsers
    ,deleteUser
    ,addUser
    ,userList
    ,userDevices
}