import httpClient from '../http';
const ENDPOINT = 'api/devices';
const ENDPOINT_TYPES = 'api/devicetypes';

const userDevices = (filters) => httpClient.get(ENDPOINT+filters);

const allDevices = () => httpClient.get(ENDPOINT+"/list?pageSize=400",{});

const deviceTypes = () => httpClient.get(ENDPOINT_TYPES,{});

const updateDevice = (device) =>{
    return httpClient.put(ENDPOINT+'/'+device.id,device)
}

const addDevice = (device) =>{
    return httpClient.post(ENDPOINT,{...device})
}

const devicePayments = (devId) => httpClient.get(ENDPOINT+"/"+devId+"/payment");

export default{
    userDevices,
    deviceTypes,
    updateDevice,
    addDevice,
    devicePayments,
    allDevices,
}