import httpClient from '../http';
const ENDPOINT = 'api/payment';

const addPayment = (payment) => httpClient.post(ENDPOINT,payment);

const updatePayment = (payment) => httpClient.put(ENDPOINT,payment);

const deletePayment = (id) => httpClient.delete(ENDPOINT+"/"+id);

export default{
    addPayment,
    updatePayment,
    deletePayment,
}
