export default {
    getFullDateString(date,locale='z'){
        var d = new Date(date+locale);

        // Get dates for January and July
var dateJan = new Date(d.getFullYear(), 0, 1);
var dateJul = new Date(d.getFullYear(), 6, 1);

// Get timezone offset
var timezoneOffset = Math.max(dateJan.getTimezoneOffset(), dateJul.getTimezoneOffset());

// Check if daylight savings
if (d.getTimezoneOffset() < timezoneOffset) {
    // Adjust date by 0 hours
    d = new Date(d.getTime() - ((1 * 60 * 60 * 1000) * 0));
  }
  else {
    // Adjust date by 1 hours
    d = new Date(d.getTime() - ((1 * 60 * 60 * 1000) * 1));
  }

       var dformat = [
           d.getDate(),
           d.getMonth()+1,
           d.getFullYear()].join('/')+' '+
          [d.getHours().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}),
           d.getMinutes().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}),
           d.getSeconds().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})].join(':');

        return dformat;
    },
    getDateString(date,locale='z'){
        var d = new Date(date+locale);
       var dformat = [
           d.getDate(),
           d.getMonth()+1,
           d.getFullYear()].join('/');

        return dformat;
    },

    isExpired(date){
        var d = new Date(date);
        return d<new Date();
    }

    ,getIconList(){
        const requireComponent = require.context('@/../public/images/', true, /\.png$/)
        var arr = [];
        for (const file of requireComponent.keys()) {
            //const componentConfig = requireComponent(file)
            const name = file
              //.replace(/index.js/, '')
              .replace(/^\.\//, '')
              .replace(/\.\w+$/, '');
            //const componentName = upperFirst(camelCase(name))
            arr.push({
                image:'/images/'+file.replace(/^\.\//, ''),
                name: name
            })
            //Vue.component(`Base${componentName}`, componentConfig.default || componentConfig)
        }
        //console.log(arr);
        return arr;  
    }
 }