<template>
    <v-app>
    <home-app-bar />

    <home-view />

    <home-footer />
  </v-app>
</template>

<script>
export default {
  components: {
      HomeAppBar: () => import('@/layouts/AppBar'),
      HomeFooter: () => import('@/layouts/Footer'),
      HomeView: () => import('@/layouts/View'),
    },
    mounted(){
      
    }
  
}
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.grecaptcha-badge{
    visibility: visible;
}
</style>
